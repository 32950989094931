import Link from "next/link";

import { useGetProfileData }      from "_common/hooks/auth";
import { useSetInterestialPopup } from "_common/hooks/global";
import BackgroundWrapper          from "_components/atoms/backgroundWrapper";
import Icon                       from "_components/atoms/icon";
import Image                      from "_components/atoms/image";

import SearchInputModal from "./components/searchModal";
import useStyles        from "./mobile.styles";

const MobilePresentation = ( { isUserLoggedIn, toggleSidebarMenu, toggleSearchModal, showSearchModal } ) => {
	const userProfileData     = useGetProfileData ();
	const { classes }         = useStyles ( undefined, { name: "header-styles" } );
	const setInterestialPopup = useSetInterestialPopup ();

	return (
		<BackgroundWrapper className = { classes.headerParent }
			type                        = "secondary"
		>
			<SearchInputModal
				showSearchModal   = { showSearchModal }
				toggleSearchModal = { toggleSearchModal }
			/>

			<div className = { classes.photoAndLogoContainer }>
				<button
					className = { classes.profileImageBtn }
					onClick   = { () => toggleSidebarMenu ( true ) }
				>
					{
						isUserLoggedIn ? (
							userProfileData?.photo ? (
								<Image
									className   = { classes.profileImage }
									placeholder = { "/images/rectangle-placeholder.webp" }
									src         = { userProfileData?.photo }
								/>
							) : null
						) : (
							<Icon
								sameInBothTheme
								className = { classes.nonLoggedInAvatar }
								name      = "avatar"
							/>
						)
					}
				</button>

				<Link href = "/">
					<Icon
						className = { classes.rooterLogo }
						name      = { "rooterLogo" }
					/>
				</Link>
			</div>

			<div className = { classes.searchAndDonationContainer }>
				<Icon
					className = { classes.searchIcon }
					name      = { "search" }
					onClick   = { toggleSearchModal }
				/>

				<Icon
					sameInBothTheme
					className = { classes.dailyTaksIcon }
					name      = { "dailyTask" }
					onClick   = { () => setInterestialPopup ( true ) }
				/>

			</div>

		</BackgroundWrapper>
	);

};

export default MobilePresentation;
