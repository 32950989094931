import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	headerParent: {
		height         : "70px",
		width          : "100%",
		display        : "flex",
		alignItems     : "center",
		justifyContent : "space-between",
		borderBottom   : `solid 0.75px ${ theme.other.colors.border.primary }`,
		position       : "fixed",
		top            : "0",
		zIndex         : "21",
		padding        : "0 2rem"
	},

	photoAndLogoContainer: {
		display    : "flex",
		alignItems : "center"
	},
	searchAndDonationContainer: {
		display    : "flex",
		alignItems : "center"
	},
	rooterLogo: {
		width: "10rem !important"
	},

	profileImage: {
		height       : "3.6rem",
		width        : "3.6rem",
		borderRadius : "100%",
		marginRight  : "2rem"
	},

	searchIcon: {
		width  : "2.5rem",
		margin : "0 2rem"
	},

	dailyTaksIcon: {
		width: "3rem"
	},

	profileImageBtn: {
		all     : "unset",
		display : "flex"
	},

	nonLoggedInAvatar: {
		height       : "3.2rem",
		width        : "3.2rem",
		borderRadius : "100%",
		marginRight  : "2rem",
		filter       : theme.other.darkMode ? "none" : "invert(1)"
	},

	logoutText: {
		fontSize      : "1rem",
		textTransform : "capitalize",
		color         : "red"
	}

} ), { name: "mobile-header" } );
